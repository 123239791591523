/* App.css */

html {
  margin: 0 auto;
  max-width: 1440px;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Mulish", sans-serif !important;
  background-color: white; /* White background */
  max-width: 1440px;
}

header {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}

header img {
  margin-left: 36px;
}

.logo {
  height: 143px;
}

nav {
  margin-right: 40px;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

nav ul li {
  margin-right: 20px;
}

nav ul li a {
  text-decoration: none;
  color: #6a0dad;
  font-size: 25px;
  padding-left: 26px;
  position: relative;
  top: 21px;
  font-family: "Mulish", sans-serif !important;
  font-style: normal;
  font-weight: 400;
}

nav a::after {
  content: "";
  position: absolute;
  left: 27px;
  bottom: -8px;
  width: 70%;
  height: 2px;
  background-color: #6a0dad;
  transform: scaleX(0);
  transition: transform 0.3s ease;
  padding-left: 3px;
}
.aboutnav::after{
  padding-left: 6px;
}
.tarifsnav::after {
  padding-left: 1px;
}
.avisnav::after {
  left: 21px !important;
  padding-left: 3px;
}

nav a:hover::after {
  transform: scaleX(
    1
  ); /* Étend la ligne à 100% de la largeur du lien au survol */
}

.separator {
  background-color: #5d18e8;
  height: 2px;
  margin: 0px auto;
  opacity: 0.7;
  width: 100%;
  position: relative;
}

.banner {
  padding-top: 200px;
  margin-top: -200px;
  width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  position: relative;
  bottom: 20px;
}
.banner img {
  width: 100%;
}

.about-section {
  display: flex;
  align-items: center;
  background: aliceblue;
  box-sizing: border-box;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  padding-top: 200px;
  margin-top: -200px;
}

.aboutcontainer {
  display: flex;
  justify-content: center;
  background: aliceblue;
  padding: 0px 120px;
  padding-bottom: 160px;
  padding-top: 140px;
  box-sizing: border-box;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  align-items: center;
}

.about-section div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about-section p {
  max-height: 400px;
}

.about-section img.audrey1 {
  margin-right: 78px;
  height: 47vh;
  border-radius: 75px;
  position: relative;
}

.contact-section {
  width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  background-image: url(./assets/contact2.webp);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}

.contacttext {
  position: absolute;
  right: 350px;
  text-align: center;
}

.contacttext p {
  color: black;
}

.contactimg {
  width: 100%;
}

.contact-section h2 {
  font-family: "Mulish", sans-serif !important;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 15px;
  letter-spacing: 0.4px;
  color: black;
  text-decoration-line: underline;
  position: relative;
  bottom: 50px;
}

.rdv {
  border-radius: 75px;
  background-color: #9e9ee5;
  color: white !important;
  padding: 20px;
}

.contact-section p {
  font-size: 25px;
  line-height: 30px;
  margin: 60px 0px;
}

.contact-section i {
  margin-right: 24px;
}

.contact-section a {
  text-decoration: none;
  color: black;
  font-size: 36px;
  line-height: 51px;
  transition: transform 0.3s ease;
}

.rdv:hover {
  transform: scaleX(1.1) !important;
  font-size: 37px;
}

.fa-brands {
  font-size: 50px;
  transition: transform 0.3s ease;
}

.fa-brands:hover {
  transform: scaleX(1.1);
}

h2,
p {
  color: #6a0dad;
  font-family: "Mulish", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  text-transform: initial;
  line-height: 25px;
}

.services-section {
  padding-top: 200px;
  margin-top: -200px;
  background: whitesmoke;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 1200px;
}

.services-section img {
  width: 63vw;
  position: relative;
  top: 12vw;
}

.services-section h2 {
  margin-top: 300px;
  position: relative;
  bottom: 19vw;
  left: 0vw;
  z-index: 1;
  width: 100%;
  padding: 40px;
  background: lavender;
}

.services-section p {
  width: 40%;
  text-align: justify;
  position: relative;
  bottom: 15vw;
}

.tarifs-section {
  background-image: url(./assets/test4.webp);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 50px;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 144px;
  margin-top: -200px;
  padding-bottom: 160px;
}

.tarifs-section h2 {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 75px;
  color: white;
  width: 250px;
  padding: 20px;
  margin-bottom: 75px;
  text-transform: uppercase;
  line-height: 36px;
}

.tarifs-section ul {
  font-family: "Mulish", sans-serif !important;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 35px;
  letter-spacing: 0.4px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 75px;
  padding: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  color: white;
}

.tarifs-section li {
  font-family: "Mulish", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  text-transform: initial;
  line-height: 25px;
  list-style: none;
  padding: 10px;
  color: white;
  font-size: 21px;
  margin: 5px 0px;
}

.pack {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 200px;
  margin-bottom: 150px;
}

.pack ul {
  height: 563px;
  margin: 45px;
}

.consult {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 270px;
}

.consult ul {
  height: 290px;
  margin: 35px;
  width: 22%;
}

.consult li {
  margin: 20px 0px;
}

h2 {
  font-size: 30px;
  text-align: center;
}

p {
  font-size: 21px;
  text-align: justify;
}

.avis-section {
  background-image: url(./assets/test2.webp);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 50px;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 340px;
  margin-top: -340px;
  height: 700px;
  justify-content: center;
  padding-bottom: 340px;
}

.aviscontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.avis-section h2 {
  border-radius: 17px;
  background-color: rgb(255 255 255 / 70%);
  color: black;
  padding: 20px;
  position: relative;
  bottom: 75px;
  text-transform: uppercase;
}

.fa-user {
  padding-right: 6px;
}

.avis-section ul {
  border-radius: 27px;
  background-color: rgb(255 255 255 / 70%);
  color: black;
  height: 80%;
  margin: 35px;
  width: 100%;
  padding: 30px;
  font-family: "Mulish", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  text-transform: initial;
  list-style: none;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.avis-section li {
  font-family: "Mulish", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  text-transform: initial;
  line-height: 23px;
  list-style: none;
  padding: 15px;
  color: black;
  font-size: 21px;
  text-align: center;
}

footer {
  text-align: center;
  margin-top: -57px;
  display: flex;
  justify-content: center;
}

footer a {
  text-decoration: none;
  color: black;
  margin: 0px 20px;
}

.copy {
  text-decoration: none;
    color: black;
    margin: 0px 20px;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
}

@media (max-width: 1760px) {
  nav ul li a {
    font-size: 21px;
  }
  .about-section p {
    font-size: 19px;
  }
  .about-section h2 {
    font-size: 26px;
  }
  .services-section p {
    bottom: 15vw;
    font-size: 19px;
  }
  .services-section h2 {
    bottom: 19vw;
    font-size: 28px;
  }
  .tarifs-section ul {
    font-size: 20px;
  }
  .tarifs-section li {
    font-size: 17px;
  }
  .tarifs-section h2 {
    font-size: 22px;
  }
  .consult ul {
    margin: 20px;
  }
  .contact-section a {
    font-size: 25px;
  }
  .rdv:hover {
    font-size: 26px;
  }
  .contact-section p {
    font-size: 22px;
  }
  .fa-brands {
    font-size: 45px;
  }
}

@media (max-width: 1590px) {
  .banner img {
    height: 720px;
  }
  .services-section p {
    bottom: 15vw;
    font-size: 19px;
    width: 59%;
  }
  .services-section h2 {
    bottom: 19vw;
    font-size: 25px;
  }
  .services-section img {
    width: 100vw;
    position: relative;
    top: 15vw;
  }
  .tarifs-section li {
    line-height: 16px;
  }
  .tarifs-section ul {
    margin: 20px;
    height: 557px;
  }
  .consult ul {
    height: 387px;
  }
  .avis-section h2 {
    font-size: 25px;
  }
  .avis-section li {
    font-size: 17px;
  }
  .contact-section a {
    font-size: 21px;
  }
  .rdv:hover {
    font-size: 22px;
  }
  .contact-section p {
    font-size: 19px;
    margin: 25px 0px;
  }
  .fa-brands {
    font-size: 41px;
  }
  .contacttext {
    right: 508px;
  }
}

@media (max-width: 1180px) {
  .banner img {
    height: 620px;
  }
  .aboutcontainer {
    height: 756px;
  }
  .about-section img.audrey1 {
    margin-right: 30px;
    height: 330px;
    position: relative;
  }
  .logo {
    height: 100px;
  }
  nav ul li a {
    font-size: 21px;
    padding-left: 12px;
  }
  nav a::after {
    left: 21px;
    padding-left: 3px;
  }
  .about-section h2 {
    font-size: 25px;
    position: relative;
    right: 38px;
  }
  .about-section p {
    font-size: 18px;
    width: 92%;
  }
  .aboutcontainer {
    padding: 0px 11px;
  }
  .services-section {
    margin-bottom: -250px;
  }
  .services-section img {
    width: 149vw;
    position: relative;
    top: 15vw;
    right: 122px;
    overflow: hidden;
  }
  .services-section p {
    bottom: 31vw;
    font-size: 18px;
    width: 84%;
    left: 0px;
  }
  .services-section h2 {
    bottom: 38vw;
    font-size: 32px;
    left: 0px;
  }
  .tarifs-section ul {
    margin: 28px;
    height: 600px;
    font-size: 21px;
    width: 100%;
  }
  .pack {
    width: 100%;
    padding: 0;
  }
  .pack ul {
    width: 100%;
    height: 600px;
  }
  .consult {
    width: 100%;
  }
  .consult ul {
    margin: 12px;
    height: 506px;
    font-size: 16px;
    line-height: 22px;
    border-radius: 50px;
  }
  .tarifs-section li {
    font-size: 18px;
    line-height: 21px;
  }
  .tarifs-section h2 {
    font-size: 18px;
  }
  .avis-section h2 {
    font-size: 23px;
  }
  .avis-section ul {
    margin: 10px;
    height: 89%;
    padding: 18px;
  }
  .avis-section li {
    font-size: 16px;
    line-height: 18px;
  }
  .contacttext {
    right: 267px;
  }
}

@media (max-width: 900px) {
  .contacttext {
    right: 235px;
  }
  .copy {
    font-size: 14px;
  }
  .about-section img.audrey1 {
    position: relative;
  }
  .pack ul {
    height: 731px;
    margin: 16px;
  }
  .tarifs-section ul {
    padding: 12px;
  }
  .consult ul {
    margin: 10px;
    height: 531px;
    font-size: 16px;
    line-height: 17px;
    border-radius: 50px;
  }
  .services-section p {
    bottom: 31vw;
    font-size: 18px;
    width: 84%;
    left: 0px;
  }
  .services-section h2 {
    bottom: 38vw;
    font-size: 25px;
    left: 0px;
  }
  .aboutcontainer {
    padding-bottom: 40px;
  }
  .aviscontainer {
    flex-direction: column;
  }
  .avis-section h2 {
    bottom: 30px;
  }
  .avis-section li {
    padding: 8px;
  }
  .contact-section a {
    font-size: 17px;
  }
  .rdv:hover {
    font-size: 18px;
  }
  .contact-section p {
    font-size: 16px;
    margin: 10px 0px;
  }
  .contact-section i {
    font-size: 18px;
  }
  footer {
    margin-top: -45px;
  }
  footer a {
    font-size: 14px;
  }
}

@media (max-width: 790px) {
  nav ul li a {
    font-size: 18px;
    margin-right: 1px;
    padding-left: 0px;
  }
.about-section p {
  font-size: 16px;
}
}

@media (max-width: 710px) {
  .pack {
    flex-direction: column;
    width: 100%;
    padding: 0;
  }
  .tarifs-section ul {
  height: 100%;
  margin: 5px;
  }
  .consult {
    flex-direction: column;
    width: 100%;
  }
  .logo {
    height: 42px;
    margin-left: 0;
  }
  nav ul li a {
    font-size: 12px;
    left: 38px;
    padding-left: 3px;
    position: relative;
    top: -10px;
  }
  .aboutcontainer {
    padding-bottom: 34px !important;
    padding: 0 1px;
  }
  .about-section {
    height: 1280px;
  }
  .about-section div {
    padding: 5px;
  }
  .about-section img.audrey1 {
    position: relative;
    height: 20vh;
    margin-right: 0px;
  }
  .about-section h2 {
    font-size: 16px;
    line-height: 4px;
  }
  .about-section p {
    font-size: 14px;
    line-height: 14px;
    width: 94%;
  }
  nav ul li {
    margin-right: 7px;
  }
  .services-section p {
    bottom: 61vw;
    font-size: 14px;
    left: 0px;
    width: 84%;
  }
  .services-section h2 {
    bottom: 78vw;
    font-size: 20px;
    left: 0px;
  }
  footer {
    margin-top: -51px;
  }
  .contacttext {
    right: 96px;
  }
}

@media (max-width: 570px) {
  .copy {
    font-size: 11px;
    line-height: 12px;
  }
  .services-section h2 {
    bottom: 78vw;
    left: 0px;
  }
  .services-section img {
    right: 85px;
  }
  .services-section p {
    bottom: 61vw;
    left: 0px;
    line-height: 14px;
  }
  .services-section {
    margin-bottom: -250px;
  }
  .contact-section a {
    font-size: 14px;
    line-height: 80px;
  }
  .rdv {
    padding: 16px;
  }
  .rdv:hover {
    font-size: 15px;
  }
  .contact-section p {
    font-size: 14px;
    line-height: 9px;
    margin: 5px 0;
  }
  .contact-section i {
    font-size: 14px;
  }
  .contacttext {
    right: 70px;
  }
  .contactimg {
    height: 245px;
  }
  .rdv {
    line-height: 51px;
  }
  .fa-brands {
    bottom: 20px;
    position: relative;
  }
  footer {
    margin-top: -43px;
  }
  footer a {
    font-size: 11px;
    z-index: 99999;
  }
}

@media (max-width: 450px) {
  nav a::after {
    left: 8px;
    padding-left: 3px;
  }
  .avisnav::after {
    left: 6px !important;
  }
  .contact-section a {
    line-height: 94px;
  }
  .contactimg {
    height: 300px;
  }
  .contacttext {
    right: 30px;
  }
  .consulth2 {
    position: relative;
    bottom: 230px !important;
  }
  .consult {
    position: relative;
    bottom: 229px;
  }
  .consult ul {
    margin-bottom: 44px;
  }
  .tarifs-section {
    padding-bottom: 0px;
    margin-bottom: -472px;
  }
  .tarifs-section h2 {
    border-radius: 25px;
    bottom: 90px;
    position: relative;
    font-size: 19px;
  }
  .tarifs-section li {
    font-size: 16px;
  }
  .pack ul {
    border-radius: 40px;
    position: relative;
    bottom: 102px;
    margin-bottom: 53px;
    font-size: 19px;
  }
  .tarifs-section {
    background-repeat: inherit;
    background-size: contain;
    background-position: inherit;
  }
  .services-section {
    height: 792px;
  }
  .services-section h2 {
    bottom: 100vw;
    font-size: 17px;
  }
  .services-section p {
    bottom: 94vw;
    left: 0;
    width: 88%;
  }
  .services-section img {
    width: 178vw;
  }
  .avis-section {
    height: 1151px;
    background-repeat: inherit;
    background-size: auto;
    background-position: initial;
  }
  .avis-section ul {
    margin-bottom: 31px;
  }
  .avis-section h2 {
    font-size: 19px;
  }
  .fa-brands {
    font-size: 22px !important;
    bottom: 15px;
  }
  .banner img {
    height: 300px;
  }
  .aboutcontainer {
    align-items: center;
    flex-direction: column;
    position: relative;
    top: 62px;
    height: 536px;
  }
  .about-section {
    height: 1268px;
  }
  .about-section div {
    justify-content: flex-end;
    align-items: center;
    padding: 55px 8px;
    bottom: 245px;
    position: relative;
  }
  .about-section img.audrey1 {
    height: 39vh;
    width: 60%;
    margin-right: 0px;
    padding: 37px;
    bottom: 131px;
  }
  .about-section p {
    position: relative;
    top: 85px;
  }
  .about-section h2 {
    position: relative;
    top: 60px;
    right: 0px;
  }
}

@media (max-width: 370px) {
  .services-section img {
    right: 59px;
    width: 219vw;
  }
  .services-section h2 {
    left: 0px;
  }
  .services-section p {
    width: 91%;
  }
  .contacttext {
    right: 21px;
  }
  .contact-section i {
    margin-right: 11px;
  }
  footer a {
    font-size: 10px;
    margin: 0 6px;
  }
}

@media (max-width: 340px) {
  .services-section p {
    bottom: 61vw;
  }
  .services-section h2 {
    bottom: 78vw;
  }
  .about-section p {
    font-size: 14px;
    line-height: 14px;
    width: 99%;
  }
}
